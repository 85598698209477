import React from 'react'
import quoteStyle from "../styles/quote.module.scss"

const Hero = () => {
    return (
        <quote className={quoteStyle.quote}>
            <div>
                <h1 className={quoteStyle.quoteHolder}><span className={quoteStyle.quoteMessage}>“The most valuable commodity I know of is information.”</span></h1>
                <h3 className={quoteStyle.quoteHolder}><span className={quoteStyle.quoteAuthor}>— Gordon Gekko</span></h3>
            </div>
        </quote>
    )
}

export default Hero