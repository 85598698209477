import Head from "../components/head"
import Hero from "../components/hero"
import HeroImage from "../assets/desk.jpg"
import Introduction from "../components/introduction"
import Layout from "../components/layout"
import Quote from "../components/quote"
import React from "react"

const IndexPage = () => {
  return (
    <Layout>
      <Head title="Home" />
      <Hero image={HeroImage} text="Welcome!" />
      <Introduction />
      <Quote />
    </Layout>
  )
}

export default IndexPage
