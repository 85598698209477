import React from "react"
import introStyle from "../styles/introduction.module.scss"

const Introduction = () => {
  return (
    <div className={introStyle.container}>
      <p className={introStyle.text}>
        My name is Milandeep Bassi and I am currently partaking in a BSc
        Computer Science at Royal Holloway, University of London. <br /> <br /> Alongside my
        degree I am currently partaking in a Level 6 Diploma in Capital Markets
        from the Chartered Institute of Securities and Investment, while having
        attained a Level 6 Diploma in Trading & Financial Market Analysis from
        the The London Institute of Banking and Finance during an internship
        with Amplify Trading. <br /> <br /> The one thing I knew I wanted to do was to get
        into finance, but in my own unique way. By studying Computer Science, I
        will be able to bring analytical skills and problem-solving skills,
        developed from creating complex algorithms and programs to finance. <br /> <br /> At
        University I have also secured the role of President in the Investment
        and Finance society for 2019 to 2020. I have used this position to
        create new roles in promoting diversity in finance and non- finance
        degrees in finance for the upcoming year. The aim for the society during
        the year is to provide tools for success allowing students to be better
        prepared for applications and have a more well-rounded understanding for
        the industry. During the previous academic year, I had secured the role
        of Trading officer in the society as a team we won the skills and
        employability cup for an outstanding impact on developing skills for our
        members.
      </p>
      <img className={introStyle.image} alt="" src="https://images.squarespace-cdn.com/content/v1/5d319b7d445d5800010386ce/1578408653669-8763SGWQLU6JSQGW9VSU/ke17ZwdGBToddI8pDm48kAdGUZez7vt4JVao2YSG6DJ7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0nQwvinDXPV4EYh2MRzm-RRkMRQBfsYad-Qtcn0hYJK3SrhycZWrvZLvAVYnZep32A/milandeepbassi.jpg"></img>
    </div>
  )
}

export default Introduction
